import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import MenuBar from '../home/MenuBar';
import Footer from '../home/v3/Footer';
import {
  colors, margins, mobileThresholdPixels, Content, Container, Subtitle2, OrderSummaryText,
}
  from '../home/v2/styledComponents';

import backgroundImg from '../../assets/order/orderSummaryHigh.jpg';

const Background = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;

  padding: ${margins.l};

  background-size: cover;
  background-color: ${colors.white};
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;

  @media (max-width: ${mobileThresholdPixels}) {
    min-height: unset;
    width: unset;
    padding: ${margins.m};
  }
`;

const PaymentContainer = styled.div`
  width: calc(700px - 2 * ${margins.l});
  background-color: ${colors.white};
  padding: 0px ${margins.l} ${margins.l};

  @media (max-width: ${mobileThresholdPixels}) {
    width: calc(100vw - 4 * ${margins.m});
    padding: 0px ${margins.m} ${margins.m};
  }
`;

const StyledSubTitle2 = styled(Subtitle2)`
  margin-top: ${margins.l};
`;

const TextContainer = styled(OrderSummaryText)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const TextLink = styled(TextContainer)`
  text-decoration: underline;
  text-align: center;
  margin-top: ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Success = () => (
  <Container>
    <MenuBar />
    <Content>
      <Background>
        <PaymentContainer>
          <StyledSubTitle2>Votre moyen de paiement a bien été enregistré</StyledSubTitle2>

          <Link
            to="/"
            style={{ flex: 1, textAlign: 'center' }}
          >
            <TextLink>retour à l’accueil</TextLink>
          </Link>
        </PaymentContainer>
      </Background>
    </Content>
    <Footer />
  </Container>
);

Success.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default Success;
